@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .interactionElements {
    @apply block w-full my-4 mx-auto py-3 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-300;
  }
  .input {
    @apply interactionElements;
  }
  
  .button {
    @apply interactionElements bg-primary text-white font-bold;
  }
}

body {
  @apply text-dark bg-gray-50;
}
